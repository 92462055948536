.checkbox-container {
  margin-top: 15%;
}

.form-input-container {
  position: relative;

  .password-error {
    position: absolute;
  }
}
