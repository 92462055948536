.input {
  display: block;
  border: none;
  outline: none;
  width: 70%;
  height: 56px;
  margin: 32px;
  font-size: 16px;
  font-weight: 500;
  font-family: $basic;
  letter-spacing: .005em;
  padding-left: 88px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;
  @include placeholder {
    color: $dark; }
  &.email {
    background: $input url('../../assets/form/icon-email.svg') left center no-repeat; }
  &.password {
    background: $input url('../../assets/form/icon-passw.svg') left center no-repeat; } }

.card-form {
  .button {
    height: 56px; } }

form {
  a {
    background: linear-gradient(0deg, #0078D4 0.31%, #1380DA 16.23%, #3C91E5 53.05%, #559CEC 81.9%, #5EA0EF 99.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; } }
