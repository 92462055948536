.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 160px;
  color: $white !important;
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  transition: all ease-out .5s;
  background-color: $pink;
  border: none !important;
  outline: none !important;
  padding: .5px 40px 0;
  &:hover {
    background-color: $v100; }
  &.blue {
    background-color: $v100;
    &:hover {
      background-color: $pink; } }
  &.mini {
    min-width: auto;
    height: 40px;
    font-size: 13px;
    font-weight: 600;
    padding: .5px 24px 0;
    &:hover {
      background: linear-gradient(360deg, $v100 0.31%, $v100 41.37%, $v100 136.3%, $v100 210.71%, $v100 256.9%); } }
  @include v-sm {
    min-width: auto;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 24px 1px; } }
