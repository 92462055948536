// Colors
.c {
  &-white {
    color: $white !important; }
  &-pink {
    color: $pink !important; }
  &-dark {
    color: $dark !important; }
  &-v60 {
    color: $v60 !important; }
  &-v80 {
    color: $v80 !important; }
  &-v80 {
    color: $v80 !important; }
  &-p60 {
    color: $p60 !important; }
  &-p80 {
    color: $p80 !important; }
  &-p100 {
    color: $p100 !important; } }

// Background Colors
.bg {
  &-body {
    background-color: $body !important; }
  &-white {
    background-color: $white !important; }
  &-pink {
    background-color: $pink !important; }
  &-dark {
    background-color: transparent !important; }
  &-v60 {
    background-color: $v60 !important; }
  &-v80 {
    background-color: $v80 !important; }
  &-v80 {
    background-color: $v80 !important; }
  &-p60 {
    background-color: $p60 !important; }
  &-p80 {
    background-color: $p80 !important; }
  &-p100 {
    background-color: $p100 !important; } }
