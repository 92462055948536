.title {
  text-transform: uppercase;
  color: $p80;
  font-weight: 500;
  line-height: 1.75;
  &-small {
    font-size: 1.1em;
    font-weight: 600; }
  &-big {
    font-size: 1.75em;
    color: $p80;
    text-transform: uppercase;
    @include v-sm {
      font-size: 1.25em; } }
  &-chapter {
    font-size: 1.1em;
    font-weight: 700;
    line-height: 1;
    @include v-sm {
      font-size: 2em; } }
  &-page {
    font-size: 1em; }
  &-section {
    font-size: 1.5em;
    @include v-sm {
      font-size: 1em; } } }
.sub {
  background: linear-gradient(0deg, #0078D4 0.31%, #1380DA 16.23%, #3C91E5 53.05%, #559CEC 81.9%, #5EA0EF 99.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }
.bg-sub {
  background: linear-gradient(360deg, #0078D4 0.31%, #1380DA 41.37%, #3C91E5 136.3%, #559CEC 210.71%, #5EA0EF 256.9%); }

.big {
  font-size: 5.25em;
  font-weight: 700;
  line-height: 1.25;
  @include v-sm {
    font-size: 2.5em; } }

.w100 {
  font-weight: 100; }
.w200 {
  font-weight: 200; }
.w300 {
  font-weight: 300; }
.w400 {
  font-weight: 400; }
.w500 {
  font-weight: 5100; }
.w600 {
  font-weight: 600; }
.w700 {
  font-weight: 700; }
.w800 {
  font-weight: 800; }
.w900 {
  font-weight: 900; }

.fz {
  &-40 {
    font-size: 3.95em;
    @include v-sm {
      font-size: 1.5em; } }
  &-32 {
    font-size: 2.75em;
    @include v-sm {
      font-size: 1.5em; } }
  &-24 {
    font-size: 2.0625em;
    @include v-sm {
      font-size: 1.25em; } }
  &-20 {
    font-size: 1.71875em;
    @include v-sm {
      font-size: 1.15em; } }
  &-18 {
    font-size: 1.5468em;
    @include v-sm {
      font-size: 1.05em; } }
  &-16 {
    font-size: 1.375em;
    @include v-sm {
      font-size: 1em; } }
  &-14 {
    font-size: 1.203em;
    @include v-sm {
      font-size: 1em; } }
  &-13 {
    font-size: 1.117em;
    @include v-sm {
      font-size: .95em; } }
  &-12 {
    font-size: 1.03125em;
    @include v-sm {
      font-size: .85em; } } }
