.page {
  padding: 10vh;
  * {
    color: #fff; }
  &-content {
    * {
      font-size: 1.25em;
      color: #444;
      font-weight: 500;
      line-height: 1.75;
      padding-bottom: 20px; }
    a {
      font-size: 1em; }
    ul {
      list-style: circle; }
    li {
      font-size: 1em; } } }
