$pink: #D62C6D;
// $body: #11111D
$body: #0E0C19;
$dark: #16192F;

$v60: #A5A6F6;
$v80: #7879F1;
$v100: #5D5FEF;

$p60: #FCDDEC;
$p80: #F178B6;
$p100: #EF5DA8;

$input: #d9d9d9;

$white: #fff;

$blue: linear-gradient(0deg, #0078D4 0.31%, #1380DA 16.23%, #3C91E5 53.05%, #559CEC 81.9%, #5EA0EF 99.81%);
$card: linear-gradient(180deg, rgba(17, 21, 42, 0.5) 0%, rgba(17, 22, 45, 0.5) 100%);
$rad: radial-gradient(100% 100% at 50% 0%, rgba(54, 31, 92, 0.5) 0%, rgba(11, 53, 83, 0.5) 100%);

$basic: 'Inter Tight', sans-serif;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

.hd {
  background: $body url(../../assets/back/hero.png) center center no-repeat;
  background-size: cover; }

.nav-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent; }

.cta {
  background: $body url(../../assets/back/net.svg) center center no-repeat;
  background-size: cover;
  background-attachment: fixed; }

.mtx {
  background: $body url(../../assets/back/matrix.svg) center center no-repeat;
  background-size: cover; }
