/* Base */

html {
  font-family: $basic;
  font-weight: normal;
  font-size: 1rem;
  font-display: swap; }
body {
  width: 100%;
  max-width: 100% !important;
  color: $dark;
  font-size: 80%;
  font-family: $basic;
  font-weight: normal;
  font-smooth: always;
  overflow-x: hidden;
  letter-spacing: .05em;
  -webkit-font-smoothing: antialised;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  overflow-x: hidden;
  background-color: $body; }

a, * a {
  color: inherit !important;
  text-decoration: none !important; }

footer {
  margin-top: 40px;
  padding: 40px 0 38px;
  background-color: darken($dark, 10%);
  * {
    color: #eeeeee !important; }
  @include v-xs {
    .text-left, .text-right {
      text-align: center !important; } } }

section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 20vh 0 !important;
  margin: 0 !important;
  &.auto {
    height: auto;
    padding: 20vh 0; } }

@keyframes colorAnimation {
  0% {
    color: rgb(134, 158, 235); }
  50% {
    color: rgb(229, 166, 245); }
  75% {
    color: rgb(166, 150, 244); }
  100% {
    color: rgb(134, 158, 235); } }

.bgc {
  animation: colorAnimation 10s linear infinite; }

.card {
  &-step {
    padding: 60px;
    background: linear-gradient(180deg, rgba(17, 21, 42, 0.5) 0%, rgba(17, 22, 45, 0.5) 100%);
    border: 1px solid #16192F;
    box-shadow: inset 0px -1px 4px rgba(82, 181, 237, 0.2);
    @include v-xs {
      padding: 40px; } }
  &-rev {
    padding: 40px 48px;
    background: linear-gradient(180deg, rgba(17, 21, 42, 0.5) 0%, rgba(17, 22, 45, 0.5) 100%);
    border: 1px solid #16192F;
    box-shadow: inset 0px -1px 4px rgba(82, 181, 237, 0.2);
    @include v-xs {
      padding: 30px 40px; } }
  &-form {
    padding: 40px 48px;
    background: linear-gradient(180deg, rgba(17, 21, 42, 0.5) 0%, rgba(17, 22, 45, 0.5) 100%);
    border: 16px solid #16192F;
    border-radius: 24px;
    box-shadow: inset 0px -1px 4px rgba(82, 181, 237, 0.2);
    @include v-xs {
      padding: 40px; } } }

.cards-step {
  .col-md-6 {
    margin: 40px auto;
    @include v-xs {
      margin: 20px auto; }
    &.c-1 {
      @include v-xs {
        order: 2; } }
    &.c-2 {
      @include v-xs {
        order: 1; } } } }

.card-talent {
  background: linear-gradient(180deg, rgba(17, 21, 42, 0.5) 0%, rgba(17, 22, 45, 0.5) 100%); }

.card-t {
  &-top {
    .t-left {
      width: 80px;
      height: 80px;
      padding: 16px 16px 12px 16px;
      border-radius: 0 0 40px 0; }
    .t-right {
      width: 80px;
      height: 80px;
      padding: 16px 12px 16px 16px;
      border-radius: 0 0 0 40px; } }
  &-skills {
    padding: 16px 40px;
    background: linear-gradient(180deg, #16224A 0%, #181E32 100%); }
  &-footer {
    padding: 20px 40px 28px;
    background: radial-gradient(100% 100% at 50% 0%, rgba(65, 32, 120, 0.5) 0%, rgba(11, 44, 67, 0.5) 100%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1); } }

footer {
  padding: 80px 0;

  background: $body url('../../assets/back/fr.svg') right bottom no-repeat;
  background-blend-mode: screen;
  background-size: 50%;
  a {
    transition: all ease-out .5s;
    &:hover {
      color: $pink !important; } } }

#candidats {
  padding: 80px 0;
  background: $body url('../../assets/back/lt.svg') left top no-repeat;
  background-blend-mode: screen;
  background-size: 50%; }

#about {
  background: $body url('../../assets/back/rt.svg') right top no-repeat;
  background-blend-mode: screen;
  background-size: 50%; }

#demo {
  background: $body url('../../assets/back/lb.svg') right top no-repeat;
  background-blend-mode: screen;
  background-size: 50%; }

#reviews {
  background: $body url('../../assets/back/rb.svg') left top no-repeat !important;
  background-blend-mode: screen;
  background-size: 50%; }
